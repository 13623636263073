// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

$ = require('jquery')
require('popper.js')
require('bootstrap')

let Utility = {
    checkGDPR: function () {
        console.log('checking GDRP approval')
        let clicked = false
        let submitButton = $('#contract-validate')
        let declineButton = $('#contract-decline')
        let gdprWarning = $('.gdpr-warning')
        let marketingWarning = $('.marketing-warning')
        let consent = $("#consent")
        let gdpr = $("#gdpr")
        let clientForm = $('#client-form')
        submitButton.on('click', function (event) {
            console.log(event)
            if (!clicked && !consent[0].checked) {
                event.preventDefault()
                clicked = true
                marketingWarning.addClass('visible')
                console.log("consent")

            }

            if (!gdpr[0].checked) {
                event.preventDefault()
                clicked = true
                gdprWarning.addClass('visible')
                console.log("gdpr")
            }

        })

        declineButton.on('click', function (event) {
            console.log("declined:", event)
            event.preventDefault()
            gdpr.removeAttr('required')
            clientForm.submit()
        })
    },
    init: function () {
        this.checkGDPR()
    }
}


document.addEventListener("turbolinks:load", function() {
    console.log('test1');
    $(document).ready(function (e) {
        Utility.init();
        console.log('test2');
    });
})

require('../../assets/stylesheets/stylesheets.js');

const images = require.context('../../assets/images', true)
const imagePath = (name) => images(name, true)
